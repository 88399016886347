<template>
  <v-container>
    <v-layout align-center justify-center>
      <v-flex xs12 sm10 md12>
        <v-data-table
          calculate-widths
          :headers="header_orders[$vuetify.breakpoint.xsOnly]"
          :items="orders"
          :items-per-page="15"
          item-key="index"
          :search="search"
          sort-by="name"
          mobile-breakpoint="0"
          class="table-cursor elevation-2"
          loading-text="Cargando ..."
          @click:row="view_doc"
        >
          <template v-slot:top>
            <v-toolbar flat rounded>
              <v-toolbar-title>Pedidos</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:[`item.issue_date`]="{ item }">
            {{ item.issue_date }}
          </template>
          <template v-slot:[`item.doc_code`]="{ item }">
            {{ item.doc_code }}
          </template>

          <template v-slot:[`item.party_name`]="{ item }">
            <b class="text-uppercase">{{ item.party_name }}</b>
            <br />
            <span v-if="$vuetify.breakpoint.xsOnly">
              <small>{{ item.issue_date }}</small>
              <br />
              <small>DID : {{ item.doc_code }}</small>
            </span>
          </template>
          <template v-slot:[`item.tax_base`]="{ item }" class="text-right">
            {{ "$ " + parseFloat(item.tax_base).toLocaleString() }}
          </template>

          <template v-slot:[`item.tax`]="{ item }" class="text-right">
            {{ "$ " + parseFloat(item.tax).toLocaleString() }}
          </template>

          <template v-slot:[`item.tItem`]="{ item }" class="text-right">
            {{ "$ " + parseFloat(item.total).toLocaleString() }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-icon v-if="item.status == 0">mdi-check</v-icon>
            <v-icon color="success" v-if="item.status == 'ENTREGADO'">
              mdi-check-all
            </v-icon>
            <v-icon color="warning" v-if="item.status == 'DESPACHADO'">
              mdi-truck-fast-outline
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-bottom-navigation background-color="#064c72" dark grow app>
      <v-row class="pa-2">
        <v-col cols="6">
          <v-select
            v-model="status"
            :items="status_lst"
            label="Estado"
            outlined
            hide-details="auto"
            dense
            @change="get_oders()"
          />
        </v-col>
        <v-col cols="6"> </v-col>
      </v-row>
      <v-spacer></v-spacer>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import createItem from "../../utils/createItem.js";

export default {
  components: {},
  data: () => ({
    categories: [],
    items: [],
    loading_status: false,
    status: "PENDIENTE",
    status_lst: ["PENDIENTE", "DESPACHADO", "ENTREGADO", "CANCELADO"],
    smart_categories: [],
    smart_lst_selected: [],
    smart_lst: [],
    menu: [],
    selectedItem: 0,
    search: "",
    tab: "orders",
    store_dialog: false,
    itemSelected: createItem(),
    add_dialog: false,
    addItm: false,
    Scart: false,
    sc: { qty: 0, subtotal: 0, discount: 0, tax: 0, total: 0 },
    clientDialog: false,
    orders: [],
    header_orders: {
      true: [
        {
          text: "Name",
          align: "start",
          value: "party_name",
          dataType: "text",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "tItem",
        },
        {
          text: "",
          align: "end",
          sortable: true,
          value: "status",
          width: 1,
        },
      ],
      false: [
        {
          text: "Fecha",
          align: "start",
          value: "issue_date",
          dataType: "date",
        },
        {
          text: "DID",
          align: "start",
          value: "doc_code",
          dataType: "text",
        },

        {
          text: "Nombre",
          align: "start",
          value: "party_name",
          dataType: "text",
        },
        {
          text: "Base",
          align: "end",
          sortable: true,
          value: "subtotal",
          dataType: "currency",
        },
        {
          text: "Impuestos",
          align: "end",
          sortable: true,
          value: "tax",
          dataType: "currency",
        },
        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "tItem",
        },
        {
          text: "",
          align: "end",
          sortable: true,
          value: "status",
          width: 1,
        },
      ],
    },
  }),
  methods: {
    get_oders() {
      var qry = {
        table: "docs",
        filters: [
          { field: "doc_type", operator: "=", value: "OR" },
          { field: "account", operator: "=", value: this.$store.getters.company.account },
          {
            field: "userId",
            operator: "=",
            value: this.$store.getters.profile.code,
          },
          {
            field: "status",
            operator: " IN ",
            value: "'PENDIENTE', 'DESPACHADO', 'ENTREGADO', 'CANCELADO'",
          },
        ],
      };

      if (this.status) {
        qry.filters.push({ field: "status", operator: "=", value: this.status });
      }

      webserver("get_table_clients", qry, (data) => {
        console.log(data);
        this.orders = data;
      });
    },
    view_doc(e) {
      //this.$router.push("Checkout/?did=" + e.doc_code);
      this.$router.push({ name: "Checkout", query: { did: e.doc_code } });
    },
  },
  mounted() {
    this.get_oders();
    this.$store.dispatch("setNavStatus", true);
  },
  watch: {},
};
</script>

<style></style>
