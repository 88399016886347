var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":"","md12":""}},[_c('v-data-table',{staticClass:"table-cursor elevation-2",attrs:{"calculate-widths":"","headers":_vm.header_orders[_vm.$vuetify.breakpoint.xsOnly],"items":_vm.orders,"items-per-page":15,"item-key":"index","search":_vm.search,"sort-by":"name","mobile-breakpoint":"0","loading-text":"Cargando ..."},on:{"click:row":_vm.view_doc},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","rounded":""}},[_c('v-toolbar-title',[_vm._v("Pedidos")])],1)]},proxy:true},{key:"item.issue_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.issue_date)+" ")]}},{key:"item.doc_code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.doc_code)+" ")]}},{key:"item.party_name",fn:function(ref){
var item = ref.item;
return [_c('b',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.party_name))]),_c('br'),(_vm.$vuetify.breakpoint.xsOnly)?_c('span',[_c('small',[_vm._v(_vm._s(item.issue_date))]),_c('br'),_c('small',[_vm._v("DID : "+_vm._s(item.doc_code))])]):_vm._e()]}},{key:"item.tax_base",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.tax_base).toLocaleString())+" ")]}},{key:"item.tax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.tax).toLocaleString())+" ")]}},{key:"item.tItem",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("$ " + parseFloat(item.total).toLocaleString())+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 0)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e(),(item.status == 'ENTREGADO')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check-all ")]):_vm._e(),(item.status == 'DESPACHADO')?_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" mdi-truck-fast-outline ")]):_vm._e()]}}],null,true)})],1)],1),_c('v-bottom-navigation',{attrs:{"background-color":"#064c72","dark":"","grow":"","app":""}},[_c('v-row',{staticClass:"pa-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.status_lst,"label":"Estado","outlined":"","hide-details":"auto","dense":""},on:{"change":function($event){return _vm.get_oders()}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{attrs:{"cols":"6"}})],1),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }